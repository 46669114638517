/* @font-face {
  font-family: "iA Writer Mono";
  src: local("iA Writer Mono"),
    url("./fonts/iA Writer Mono/Webfonts/iAWriterMonoS-Regular.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Ubuntu Mono";
  src: local("Ubuntu Mono"),
    url("../fonts/ubuntu-font-family-0.83/UbuntuMono-R.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu Mono";
  src: local("Ubuntu Mono"),
    url("../fonts/ubuntu-font-family-0.83/UbuntuMono-B.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.TextTVPage,
.TextTVSkeletonPage,
.u-max-width-texttvpage {
  font-family: "Ubuntu Mono", Consolas, Inconsolata, monospace;
  background: var(--text-tv-background);
  color: #ddd;
  text-align: center;
  line-height: 1;
  padding: 10px 10px 40px 0;
}
.TextTVPage,
.TextTVSkeletonPage,
.u-max-width-texttvpage,
.ListPageNum {
  font-size: var(--text-tv-font-size);
}

.TextTVPage--skeleton .TextTVPage__inner {
  position: relative;
}

/* @media only screen and (min-width: 250px) {
  .TextTVPage {
    font-size: 10px;
  }
}

@media only screen and (min-width: 320px) {
  .TextTVPage {
    font-size: 14px;
  }
}

@media only screen and (min-width: 375px) {
  .TextTVPage {
    font-size: 16px;
  }
}

@media only screen and (min-width: 414px) {
  .TextTVPage {
    font-size: 18px;
  }
} */
/* Lite mindre storlek på desktop så vi får plats med scrollbar */
/* .plt-desktop .TextTVPage {
  font-size: 4.7vw;
} */

.TextTVSkeletonPage {
  white-space: pre;
}

.ListHeadline {
  color: var(--text-tv-color-yellow) !important;
  font-size: var(--text-tv-font-size) !important;
  font-weight: bold !important;
}
.ListHeadlineSidor {
  color: var(--text-tv-color-white) !important;
  font-size: var(--text-tv-font-size) !important;
  /* font-weight: bold !important; */
}

.texttv-header-title-link {
  color: var(--ion-color-contrast);
  text-decoration: none;
}

.texttv-logo {
  display: inline-block;
  width: 28px;
  vertical-align: middle;
  position: relative;
  margin-right: 10px;
}

.texttv-pagenumber-input input[type="number"]::-webkit-outer-spin-button,
.texttv-pagenumber-input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.texttv-pagenumber-input input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 540px) {
  .TextTVPage,
  .TextTVSkeletonPage,
  .ListHeadline,
  .ListHeadlineSidor,
  .u-max-width-texttvpage,
  .ListPageNum {
    font-size: 22px !important;
  }
}

/* Styles for slide over */
/* http://www.mobilexweb.com/blog/ios9-safari-for-web-developers */
/* iPad Air or iPad Mini */
/* @media only screen and (device-width: 768px) and (width: 320px) {
  .TextTVPage,
  .TextTVSkeletonPage,
  .ListHeadline,
  .ListHeadlineSidor,
  .u-max-width-texttvpage {
    outline: 2px solid red;
  }
} */

/* iPad Pro */
/* @media only screen and (device-width: 1024px) and (width: 320px) {
  .TextTVPage,
  .TextTVSkeletonPage,
  .ListHeadline,
  .ListHeadlineSidor,
  .u-max-width-texttvpage {
    outline: 2px solid green;
    background: pink;
    font-size: 14px !important;
  }
} */

/*
Styles for Split View
*/
