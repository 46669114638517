/* @import "~animate.css/animate.css"; */

/* ion-content{
  --ion-background-color:#111D12;
} */

/*
THeming
https://ionicframework.com/docs/theming/advanced
*/

:root {
  --text-tv-background: black;
  --text-tv-color: #e8eaed;
  --text-tv-color-rgb: 32, 33, 36;
  --text-tv-font-family: "Ubuntu Mono", Courier, monospace;
  --text-tv-font-size: 4.8vw;
  --text-tv-color-white: var(--text-tv-color);
  /* Används av sidlisting bland annat  */
  --text-tv-color-yellow: #e2e200;
  --text-tv-color-cyan: #00c8ee;

  /* --ion-background-color: var(--text-tv-background);
  --ion-background-color-rgb: rgb(17, 30, 63);
  --ion-text-color: var(--text-tv-color); */
  --ion-text-color-rgb: var(--text-tv-color-rgb);
  --ion-font-family: var(--text-tv-font-family);

  /* --ion-text-color: #fff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-font-family: "Ubuntu Mono"; */

  /* --ion-background-color: var(--text-tv-background); */

  /* Primary color = blue things, like the header */
  --ion-color-primary: #0066b5;
  --ion-color-primary-rgb: 0, 102, 181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /* --ion-color-secondary: #3b89c5;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea; */

  /* --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff; */

  /* --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070; */

  /* --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a; */

  /* --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454; */

  /* --ion-color-dark: #222428; */
  /* --ion-color-dark: var(--text-tv-background);
  --ion-color-dark-rgb: 17, 30, 63;
  --ion-color-dark-contrast: #eee;
  --ion-color-dark-contrast-rgb: 238, 238, 238;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e; */

  /* --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab; */

  /* --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9; */

  /* Default i Ionic blir för smal i Iphone */
  --ios-popover-width: 275px;

  /* Höjd på annons. Får från AdMob via callback och denna variabel uppdateras då. */
  --text-tv-ad-height: 0px;

  /* Höjd på tab bar */
  --text-tv-tab-bar-height: 0px;
}

/*
Google Keep mörka färger
Bakgrund: #202124
Textfärg: #e8eaed
Toast bakgrundsfärg: #313235
*/

/* Dessa värden är skapade på https://ionicframework.com/docs/theming/themes */
:root {
  --ion-background-color: #000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #e8eaed;
  --ion-text-color-rgb: 232, 234, 237;

  --ion-color-step-50: #2a2b2e;
  --ion-color-step-100: #343538;
  --ion-color-step-150: #3e3f42;
  --ion-color-step-200: #48494c;
  --ion-color-step-250: #525356;
  --ion-color-step-300: #5c5d60;
  --ion-color-step-350: #66676a;
  --ion-color-step-400: #707174;
  --ion-color-step-450: #7a7b7e;
  --ion-color-step-500: #848689;
  --ion-color-step-550: #8e9093;
  --ion-color-step-600: #989a9d;
  --ion-color-step-650: #a2a4a7;
  --ion-color-step-700: #acaeb1;
  --ion-color-step-750: #b6b8bb;
  --ion-color-step-800: #c0c2c5;
  --ion-color-step-850: #cacccf;
  --ion-color-step-900: #d4d6d9;
  --ion-color-step-950: #dee0e3;
}

ion-tab-bar {
  --color-selected: #eee;
}

/* Debug for link hit detection */
.dot-container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

b {
  position: absolute;
  width: 1px;
  height: 1px;
  background-color: green;
}

@keyframes fadeInUp {
  from {
    opacity: 0.75;
    transform: translate3d(0, 8px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0.75;
    transform: translate3d(8px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0.5;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 8px, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.TextTVPage {
  user-select: auto;
}

/* Skeleton-sidan tonas in om laddningen inte syns direkt */
.TextTVPage--skeleton {
  /* Animation properties removed */
  opacity: 1;
}

.TextTVPage--isDoneLoading {
  /* Animation properties removed */
  opacity: 1;
}

.u-max-width-texttvpage {
  max-width: 20em;
}

.TextTVNextPrevSwipeNav {
  min-height: 40vh;
  display: flex;
  align-items: center;
  opacity: 0.5;
}

.TextTVNextPrevSwipeNav__icon,
.TextTVNextPrevSwipeNav__number {
  display: inline-block;
  vertical-align: middle;
}

.TextTVNextPrevSwipeNav__inner {
}

.TextTVNextPrevSwipeNav--prev {
  left: 0.5rem;
}

.TextTVNextPrevSwipeNav--next {
  right: 0.5rem;
}

.TextTVNextPrevSwipeNav--prev .TextTVNextPrevSwipeNav__number {
  margin-left: 0.5rem;
}

.TextTVNextPrevSwipeNav--next .TextTVNextPrevSwipeNav__number {
  margin-right: 0.5rem;
}

/* Gör plats för annonser (klicka på dom!) */
.ion-page ion-content {
  --offset-bottom: calc(-1 * var(--text-tv-ad-height)) !important;
}

/* På större skärmar så är sidemenu synlig och behöver
också få utrymme för annonsen som lite fult och olyckligt sträcker sig över hela sidan.
Lägg även på tab-bar-höjd här.
*/
ion-menu.split-pane-side.menu-side-start.menu-pane-visible ion-content {
  --offset-bottom: calc(
    -1 * (var(--text-tv-ad-height) + var(--text-tv-tab-bar-height))
  ) !important;
}

/* 
Positionera toast ovanför tabbar.
*/
.TextTVPage_Toast {
  top: -4em;
  --background: #313235;
  --color: var(--text-tv-color);
  --button-color: #88b3f6;
  --border-style: solid;
  --border-width: 1px;
  --border-color: #4a4a4a;
}

/* Placera toasts ovanför flikar + annonser */
.TextTVPage_Toast {
  top: calc(
    -1 * (var(--text-tv-ad-height) + var(--text-tv-tab-bar-height))
  ) !important;
}

/* Popover med sid-åtgärder är för smal i Ios */
ion-popover.sc-ion-popover-ios-h {
  --width: var(--ios-popover-width);
}

ion-popover.sc-ion-popover-ios {
  left: calc(var(--ios-popover-width) - var(--ion-safe-area-right, 0px));
}

ion-fab {
  margin-bottom: calc(var(--text-tv-ad-height, 0));
}

.LargeFontDetector {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  background-color: white;
  /* To see the font detector move up the z-index */
  z-index: -1;
  opacity: 0;
}

.LargeFontDetector .dynamic {
  background-color: red;
  white-space: nowrap;
  display: inline-block;
}
